.filter-multiselect {
  @apply h-[38px] text-xs first:[&>.searchWrapper]:mt-1.5 first:[&>.searchWrapper]:border-none first:[&>.searchWrapper]:p-0;
}
.text-hover .false:hover {
  @apply text-primary;
}
.filter .btn-sm {
  @apply !h-[2.2rem];
}
td .btn-sm {
  height: 2rem !important;
}
.btn-sm {
  @apply !h-[2.2rem];
}

.filter-dropdown .css-13cymwt-control {
  margin-top: -9px;
  border: none;
  min-height: 0px;
  max-height: 20px;
}

.filter-dropdown .css-1u9des2-indicatorSeparator {
  display: none;
}

.filter-dropdown .css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
  min-height: 0px;
  max-height: 20px;
  margin-top: -9px;
}

.filter-dropdown .css-1fdsijx-ValueContainer {
  padding: 0 !important;
  margin-bottom: 5px;
}

.filter-dropdown .css-1xc3v61-indicatorContainer {
  padding-top: 2px;
}

.filter-dropdown .css-15lsz6c-indicatorContainer {
  padding-top: 2px;
}

.filter-dropdown .css-1jqq78o-placeholder {
  margin: 0;
}

.filter-dropdown .css-1dimb5e-singleValue {
  margin: 0;
  font-size: 12px;
}

.filter-dropdown .css-hlgwow {
  padding: 0;
}

.filter-dropdown .css-1jqq78o-placeholder {
  margin: 0;
}

.swiss-filters {
  @apply filter-dropdown background h-[50px] border px-2.5 filter lg:w-[230px] xl:w-[20%] 2xl:w-[220px];
}
