@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('./style/main.css');
@import url('./style/icon.css');
@import url('./style/tooltip.css');
@import url('./style/select.css');
@import url('./style/tabs.css');
@import url('./style/filter.css');
@import url('./style/datepicker.css');
@import url('./style/typography.css');
@import url('./style/toggle.css');
@import url('./style/modal.css');
@import url('./style/checkbox.css');
@import url('./style/input.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .main-card {
    @apply flex flex-col space-y-[16px] rounded-lg border-gray-line md:border md:p-[24px];
  }

  .order-form-card {
    @apply background rounded-lg border-gray-line md:border md:p-6;
  }
}
