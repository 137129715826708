.MuiPickersLayout-root {
  @apply !h-auto !w-full !bg-background !font-['Inter'] lg:!w-[352px] lg:!rounded-lg lg:!shadow-calendar;
}

.MuiPickersLayout-contentWrapper {
  @apply !flex-col-reverse;
}

.MuiDayCalendar-header {
  @apply !flex !justify-around;
}

.MuiDayCalendar-weekContainer {
  @apply !flex !justify-center md:!my-2 lg:!my-[1px];
}

.MuiDayCalendar-weekContainer .MuiPickersDay-root {
  @apply !mx-0 !my-0 !h-[44px] !p-0 !text-sm;
}

.MuiButtonBase-root {
  @apply !text-text;
}

.MuiTab-root.Mui-selected {
  @apply !text-primary;
}

.MuiTabs-scroller {
  @apply !mx-auto !max-w-[352px];
}

.MuiTabs-indicator {
  @apply top-0 !bg-primary;
}

.MuiDayCalendar-weekDayLabel {
  @apply !mx-1 !flex !justify-around;
}

.MuiPickersDay-root:not(.Mui-selected) {
  @apply !text-text !transition-all;
}

.MuiPickersDay-root.Mui-selected {
  @apply !bg-primary !text-white;
}

.MuiPickersCalendarHeader-labelContainer {
  @apply !text-base !font-semibold !text-text;
}

.MuiTypography-root {
  @apply !text-gray-1;
}

.MuiTypography-root.MuiTypography-caption.MuiClock-meridiemText {
  @apply !text-text;
}

.MuiClockNumber-root {
  @apply !text-text;
}

.MuiClockNumber-root.Mui-selected {
  @apply !text-white;
}

.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb {
  @apply !border-primary !bg-primary;
}

.css-1bedvil-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.css-1bbq4cy-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  @apply !bg-primary;
}

.css-1bedvil-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton
  .MuiTypography-root.MuiTypography-caption.MuiClock-meridiemText,
.css-1bbq4cy-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton
  .MuiTypography-root.MuiTypography-caption.MuiClock-meridiemText {
  @apply !text-white;
}
.MuiPickersDay-today {
  @apply !border-gray-1;
}

.MuiSvgIcon-root:not(.MuiPickersCalendarHeader-switchViewIcon) {
  @apply !h-6 !w-6;
}

.selected-range {
  @apply !border !border-dashed !border-primary/75 !transition-all;
}

.react-datepicker__input-container {
  height: 100%;
  width: 100%;
}

.react-datepicker__close-icon::after {
  content: 'X' !important;
}

.MuiDayCalendar-weekContainer .MuiPickersDay-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
