.filled-icon {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}
.material-symbols-outlined {
  font-weight: 300;
}
.sidemenu .material-symbols-outlined {
  font-weight: 200 !important;
}
