.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip-body {
  width: max-content;
  max-width: 397px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.35));
  transform: translateY(-50%);

  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.tooltip-right {
  top: 30%;
  left: 21px;
  bottom: auto;
}

.tooltip-left {
  top: 50%;
  right: 150%;
  bottom: auto;
}

.tooltip-top {
  right: 50%;
  transform: translate(50%, -100%);
}

/*Leaving this as an example, if a decision is made to bring back arrows*/
/*.tooltip-top::after {*/
/*  @apply content-[''] absolute -z-[1] w-3 h-3 bg-gray-1 !top-3/4;*/
/*  clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 100%, 50% 100%, 0 50%);*/
/*}*/

.tooltip-bottom {
  top: -150%;
  left: 100%;
  transform: translate(-50%, 50%);
}

.tooltip:hover .tooltip-body {
  visibility: visible;
  opacity: 1;
}
