:root {
  --mobile-content-x-padding: 1rem;
}

body {
  font-family: 'Inter', sans-serif;
}
.header-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.thin-border {
  border-bottom: 1px #ff8c00 solid !important;
}

#json-pretty pre {
  @apply background;
}

.align-dropdown {
  bottom: -30px !important;
}

/* Override the default container width */
.container {
  max-width: 100vw !important;
}

/* width */
::-webkit-scrollbar {
  @apply h-2 w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply h-[6px] rounded-[1.5rem] bg-gray-5;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
